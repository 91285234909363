import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../components/layout/layout'
import { Seo } from '../../components/seo/default'

export default function Page({
    pageContext: {
        title,
        sections
    }
}) {
    function prev(i) {
        const elem = document.getElementById(`carousel__${i}`)
        elem.scrollTo(elem.scrollLeft - 158 < 0 ? 0 : elem.scrollLeft - 158, 0);
    }

    function next(i) {
        const elem = document.getElementById(`carousel__${i}`)
        elem.scrollTo(elem.scrollLeft + 158, 0);
    }

    return (
        <React.Fragment>
            <Layout>
                <Seo title={`${title}`} />
                <div className="w-full py-4 px-2 sm:py-8 sm:px-8 max-w-7xl mx-auto" id="carousels-page-template">
                    <h1 className='mb-4 pb-2 uppercase text-base sm:text-xl font-bold'>{title}</h1>

                    <div className="sections">
                        {
                            sections.map((section, i) => (
                                <div className='section mb-8' key={i}>
                                    <div className="section-header mb-2 flex items-center justify-between">
                                        <h3 className='font-normal uppercase my-0 section-title'>{section.title}</h3>

                                        <div className="arrows flex items-center">
                                            <button
                                                className='px-2'
                                                onClick={() => prev(i)}
                                            >&lt;</button>
                                            <button
                                                className='px-2'
                                                onClick={() => next(i)}
                                            >&gt;</button>
                                        </div>
                                    </div>
                                    <div className="items flex flex-nowrap overflow-x-auto scrollbar-hide scroll-smooth" id={`carousel__${i}`}>
                                        {
                                            section.items.map((item, j) => (
                                                <Link to={item.link} key={j}>
                                                    <div className="item border border-gray-300 mr-2">
                                                        <div className="item-image">
                                                            <img src={item.image} alt={item.title} className="w-full h-full" />
                                                        </div>
                                                        <div className="py-4 px-2">
                                                            <p>{item.title}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}